import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FiClock } from "@react-icons/all-files/fi/FiClock";
import { Link } from "gatsby";
import "./SwitchBlog.scss";

const SwitchBlog = ({
  slug,
  image,
  category,
  title,
  date,
  blogPara,
  adminName,
  adminLink,
  readTime,
}) => {
  return (
    <div className="page_article">
      <div className="all_posts">
        <div className="row my-4">
          <div className="col-lg-6 col-sm-6 p-0 articles">
            <div className="Posts_image">
              <Link to={`/${slug}/`}>
                <GatsbyImage
                  image={image.localFile?.childImageSharp?.gatsbyImageData}
                  style={{
                    height: "100%",
                    width: "100%",
                    minHeight: "270px",
                  }}
                  imgStyle={{ height: "100%" }}
                  alt="reviews-content"
                  className="img-fluid"
                  loading="eager"
                  objectFit="cover"
                />
                <div className="overlay-content">
                  <div className="read-more">
                    <Link to={`/${slug}/`}>
                      <div className="text">READ MORE</div>
                      <div className="post-reading-time d-flex mt-lg-5 align-items-center">
                        <div className="icon  mb-lg-1">
                          <FiClock />
                        </div>
                        <span>{readTime}</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 articles">
            <div className="post_content d-flex flex-column h-100 px-3">
              <ul className="d-flex pt-3 pt-lg-0">
                {category &&
                  category.map((e) => {
                    return (
                      <>
                        <li className="article-list">
                          <Link to={`/category/${e.category.toLowerCase()}/`}>
                            {e.category}
                          </Link>
                        </li>
                      </>
                    );
                  })}
              </ul>
              <h2 className="pt-3 pt-lg-0">
                <Link
                  to={`/${slug}/`}
                  dangerouslySetInnerHTML={{ __html: title }}
                ></Link>
              </h2>
              <p className="pt-lg-2 blog-para">{blogPara}</p>
              <div className="d-flex align-items-center admin mt-auto">
                {/* <Link to={`/${adminLink}/`} className="admin-content align-items-center">
                  by
                  <span className="fw-bold">
                    {` ${adminName}`}
                    <span>.</span>
                  </span>
                </Link> */}
                <div className="august">
                  <span>{date}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(SwitchBlog);