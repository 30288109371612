import React, { useState } from "react";
import { ImPinterest } from "@react-icons/all-files/im/ImPinterest";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram";
import { FaMediumM } from "@react-icons/all-files/fa/FaMediumM";
import "./RightSideContent.scss";
import axios from "axios";
import "./ArticleForm.scss";
import {
  caseSuccess,
  caseError,
  caseErrorCheck,
  caseSuccessCheck,
} from "../components/Validations/SubscriptionForm";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { RiArrowDropRightLine } from "@react-icons/all-files/ri/RiArrowDropRightLine";

const ArticleForm = () => {
  const [email, setEmail] = useState("");
  const [check, setCheck] = useState(false);
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkResponse, setCheckResponse] = useState("");
  const [accessToken, setAccessToken] = useState('SW8DnHlcXfL0qZ3p3A6JVFCGuZHgbQBY')
  const [refreshToken, setRefreshToken] = useState('6A9C0ye3apjd8TqtIdXNiKaPCqtEuLu9')

  const refreshTokenUrl = `https://auth.aweber.com/oauth2/token?grant_type=refresh_token&refresh_token=${refreshToken}`;
  const refreshAccessToken = async () => {
    try {
      const res = await fetch(refreshTokenUrl, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Basic ' + btoa('FawoOSmefXDLBCV6xcxybCfKb2Fiu2wC:TXyuJq1DjoM1sMkkgpEtv84h71twxLXf'),
        },
        method: "POST",
      })
      const data = await res.json()
      setAccessToken(data.access_token);

      //after refresh token adding subscriber
      // ----------------------------------------------------------------------------------------------------

      await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${data.access_token}`
        },
        method: "POST",
        body: JSON.stringify({
          email: email
        })
      })
        .then(data => {
          if (data.status == 201) {
            caseSuccess("responseMsgArticleForm");
            resLoad("You have successfully subscribed.", false);
            setEmail('');
            setCheck(false)
          }
          else {
            //in case the email already used or unacceptable email
            caseError("responseMsgArticleForm");
            resLoad("Use Another Email.", false);
          }
        })
        .catch(err => {
          caseError("responseMsgArticleForm");
          resLoad("Invalid Email.", false);
        })
      // ----------------------------------------------------------------------------------------------------
    } catch (e) {
      console.log(e)
    }
  }

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const checkInput = (e) => {
    setCheck(e.target.checked);
  };

  function resLoad(setRes, setLoad) {
    setResponse(setRes);
    setIsLoading(setLoad);
  }

  const Useremail = async () => {
    setIsLoading(true);
    if (email != "") {
      await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        },
        method: "POST",
        body: JSON.stringify({
          email: email
        })
      })
        .then(data => {
          if (data.status == 201) {
            caseSuccess("responseMsgArticleForm");
            resLoad("You have successfully subscribed.", false);
            setEmail('');
            setCheck(false)
          }
          else if (data.status == 401) {
            refreshAccessToken();
            //code to refresh access token 
          }
          else {
            caseError("responseMsgArticleForm");
            resLoad("Use Another Email.", false);
          }
        })
        .catch(err => {
          caseError("responseMsgArticleForm");
          resLoad("Invalid email.", false);
        })
    } else {
      caseError("responseMsgArticleForm");
      resLoad("Please enter all details", false);
    }
  };
  return (
    <>
      <div className="article-form px-2 pt-sm-0 pt-5">
        <h5 className="text-left pb-2">FOLLOW US</h5>
        <div className="d-flex article-form-icon">
          <a
            href="https://facebook.com/Phonera.in"
            target="_blank"
            rel="nofollow noopener"
            aria-label="Facebook"
          >
            <FaFacebook className="icon_color facebook socio-icons" />
          </a>
          <a
            href="https://www.instagram.com/phonera.in"
            target="_blank"
            rel="nofollow noopener"
            aria-label="Instagram"
          >
            {" "}
            <AiOutlineInstagram className="dark icon_color insta socio-icons" />
          </a>
          <a
            href="https://pinterest.com/Phonerain/_saved"
            target="_blank"
            rel="nofollow noopener"
            aria-label="Pinterest"
          >
            <ImPinterest className="icon_color pinterest socio-icons" />
          </a>
          <a
            href="https://medium.com/@phonera"
            target="_blank"
            rel="nofollow noopener"
            aria-label="Medium"
          >
            <FaMediumM className="icon_color medium socio-icons" />
          </a>
        </div>
      </div>
      <div className="grey-background mb-3 mt-5">
        <div className="inner-container">
          <div className="formtxt icon_color text-uppercase">
            Subscription Form
          </div>
          <input
            className="ps-2 d-flex justify-content-center align-items-center"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleChange}
          />
          <p className="responseMsgArticleForm">{response}</p>
          {isLoading ? <LoadingSpinner /> : null}
          <button
            className="btn subscribe-button my-3"
            type="button"
            onClick={() => {
              if (!check) {
                caseErrorCheck("checkResArticleForm");
                setCheckResponse(
                  "Please confirm that you agree with our policies."
                );
              } else {
                Useremail();
              }
            }}
          >
            SUBSCRIBE
            <span>
              <RiArrowDropRightLine className="icon" />
            </span>
          </button>
          <div className="checkbox-content">
            <input
              type="checkbox"
              className="check"
              checked={check}
              onChange={(e) => {
                checkInput(e);
                if (check) {
                  caseErrorCheck("checkResArticleForm");
                  setCheckResponse(
                    "Please confirm that you agree with our policies."
                  );
                } else {
                  caseSuccessCheck("checkResArticleForm");
                  setCheckResponse("");
                }
              }}
            />
            <p className="mb-3">
              By checking this box, you confirm that you have read and are
              agreeing to our terms of use regarding the storage of the data
              submitted through this form.
            </p>
            <p className="checkResArticleForm">{checkResponse}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ArticleForm);