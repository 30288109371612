import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ArticlePosts from "./ArticlePosts";
import ArticleForm from "./ArticleForm";
import FeaturedCategories from "./FeaturedCategories";
import Layout from "../components/Layout/layout";
import SwitchBlog from "./SwitchBlog";
import "./Blog.scss";

interface Admin {
  pageContext;
  props;
  title;
  path: string;
}

const Admin: React.FC<Admin> = (props) => {
  const { pageContext } = props;
  const data = useStaticQuery(graphql`
    {
      allStrapiPost {
        totalCount
        nodes {
          slug
          date
          Title
          id
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          blogPara
          ReadTime
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 720)
              }
            }
          }
        }
      }
    }
  `);
  const [numberOfItems, setNumberOfItems] = useState(10);
  const posts = data.allStrapiPost.nodes;
  const admins = posts.filter((post) => {
    return post.admin_users[0].username === pageContext.title;
  });

  return (
    <Layout>
      <div className="review py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="page_header pb-5">
                <div className="admin_page">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="d-flex admin_name">
                          <StaticImage
                            src="../images/admin-two.png"
                            alt="A dinosaur"
                            layout="fixed"
                            width={100}
                            height={100}
                            loading="eager"
                          />

                          <div>
                            <h1>{pageContext.title}</h1>
                            <div className="archive_count">
                              {admins.length} {pageContext.posts}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <>
                {admins
                  .slice(0, numberOfItems ?? 3)
                  .map((e) => {
                    return pageContext.title.toLowerCase().replaceAll(" ", "-") ===
                      pageContext.blogSlug ? (
                      <SwitchBlog
                        slug={e.slug}
                        image={e.image}
                        category={e.blog_categories}
                        title={e.Title}
                        blogPara={e.blogPara}
                        date={new Date(e.date)
                          .toDateString()
                          .split(" ")
                          .slice(1, 4)
                          .toString()
                          .replace(",", " ")}
                        adminName={e.admin_users[0].username}
                        adminLink={e.admin_users[0].username?.toLowerCase().replace(" ", "-")}
                        readTime={e.ReadTime}
                      />
                    ) : null;
                  })
                }
                <div className={admins.length > 10 ? "pt-5" : "d-none"}>
                  {admins.length >= numberOfItems && (
                    <button
                      className="cs-load-more"
                      onClick={() =>
                        setNumberOfItems((state) => state + 10)
                      }
                    >
                      Load More
                    </button>
                  )}
                </div>
              </>
            </div>

            <div className="col-lg-4 px-lg-5 mt-lg-0 mt-md-5">
              <div className="h-100">
                <div className="d-flex flex-column flex-sm-row flex-lg-column set-sticky">
                  <div>
                    <ArticleForm />
                  </div>
                  <div className="pt-lg-3 pt-sm-0 col-lg-12 col-sm-6  scrolling-effect">
                    <ArticlePosts />
                    <FeaturedCategories />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default React.memo(Admin);