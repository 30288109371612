import { Link } from "gatsby";
import React from "react";
import { BiRightArrowAlt } from "@react-icons/all-files/bi/BiRightArrowAlt";
//@ts-ignore
import comparison from "../images/ComparisonBG.jpg";//@ts-ignore
import phone from "../images/phnBG.jpg";//@ts-ignore
import spec from "../images/specBG.jpg";//@ts-ignore
import review from "../images/reviewBG.jpg";

const Category = ({ text, url, count, bgImage }) => {
  return (
    <Link to={`${url}/`}>
      <div
        className="categories d-flex justify-content-between mt-3"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="comparison">{text}</div>
        <div className="number-count text-center">
          <span>{count}</span>
          <div className="arrow-wrapper">
            <BiRightArrowAlt />
          </div>
        </div>
      </div>
    </Link>
  );
};
const FeaturedCategories = () => {
  return (
    <div className="featured-categories ">
      <div className="row">
        <div className="featured-title">
          <span>FEATURED CATEGORIES</span>
        </div>
        <Category
          text="Comparison"
          count="2"
          url="/category/comparison"
          bgImage={comparison}
        />
        <Category
          text="Phones"
          count="22"
          url="/category/phones"
          bgImage={phone}
        />
        <Category
          text="Spec"
          count="9"
          url="/category/spec"
          bgImage={spec}
        />
        <Category
          text="Reviews"
          count="12"
          url="/category/reviews"
          bgImage={review}
        />
      </div>
    </div>
  );
};
export default React.memo(FeaturedCategories);