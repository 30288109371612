import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./RightSideContent.scss";
import { graphql, Link, useStaticQuery } from "gatsby";
const ArticleRightContent = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { order: DESC, fields: date }, limit: 4) {
        nodes {
          slug
          date
          Title
          id
          blog_categories {
            category
            slug
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <div className="posts-content pt-md-0 pt-3">
        <div className="trending-title">
          <span>TRENDING POSTS</span>
        </div>
        <div className="row">
          {data.allStrapiPost.nodes.slice(0, 4).map((e, i) => {
            return (
              <div className="post-border" key={i}>
                <div className="row">
                  <div className="col-3 col-md-4">
                    <Link to={`/${e.slug}/`}>
                      <div className="posts-image w-100 h-100 py-4">
                        <GatsbyImage
                          image={
                            e.image?.localFile?.childImageSharp?.gatsbyImageData
                          }
                          style={{
                            height: "100%",
                            width: "100%",
                            minHeight: "100px",
                          }}
                          imgStyle={{ height: "88%" }}
                          alt="reviews-content"
                          className="img-fluid"
                          loading="eager"
                          objectFit="cover"
                        />
                      </div>
                    </Link>
                  </div>

                  <div className="col-9 col-md-8 d-flex align-items-center">
                    <div className="right-content py-3">
                      <Link
                        to={`/${e.slug}/`}
                        dangerouslySetInnerHTML={{ __html: e.Title }}
                      ></Link>
                      <ul className="pt-3">
                        <li>
                          {new Date(e.date)
                            .toDateString()
                            .split(" ")
                            .slice(1, 4)
                            .toString()
                            .replace(",", " ")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default React.memo(ArticleRightContent);